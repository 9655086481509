<template>
	<div class="Header flex justify-between">
		<div class="w">
			<!-- 登录过后展示 -->
			<div
				v-if="userInfo.user_token"
				class="login-in flex justify-between ptb12 font-33 fs12"
			>
				<div>Hi~欢迎来到好好帮</div>
				<div
					@click="logout"
					class="cursor"
					v-if="userInfo.user_token.length > 4"
				>
					退出登录
				</div>
				<div @click="logouts" class="cursor" v-else>登录</div>
			</div>
			<div class="con flex justify-between">
				<router-link to="/">
					<img
						class="logo"
						src="@/assets/imgs/header-left-bg.png"
						alt="好好帮"
					/>
				</router-link>
				<div v-if="userInfo.user_token" class="right flex align-center">
					<div class="city-wrap flex align-center">
						<div class="weather flex align-center font-33 fs12">
							<img
								v-if="curWeather.wea_img"
								:src="
									$config.OSS_IMG_BASE_URL +
									'weather-icon/' +
									curWeather.wea_img +
									'.png'
								"
							/>{{ curWeather.wea || "_" }} {{ curWeather.tem_night || "_" }} /
							{{ curWeather.tem_day || "_" }}℃
						</div>
						<div class="line mlr24 font-33">|</div>
						<div class="city">
							<el-cascader
								v-model="curArrs"
								:options="cityArrs"
								@change="change"
								:props="{
									value: 'c',
									label: 'n',
									children: 'd',
								}"
								placeholder="请选择"
								:show-all-levels="false"
								clearable
								filterable
							></el-cascader>
						</div>
						<!-- <div>
							<div class="city flex align-center font-33 fw-7 cursor">
								{{ $store.state.curCity
								}}<i class="el-icon-caret-bottom ml24"></i>
							</div>
						</div> -->
						<!-- <div>
							<div class="city flex align-center font-33 fw-7 cursor">
								{{ $store.state.curCity
								}}<i class="el-icon-caret-bottom ml24"></i>
							</div>
						</div> -->
					</div>
					<div
						@click="jumpAbout"
						class="user cursor flex align-center"
						v-if="userInfo.user_token.length > 4"
					>
						<img class="mr16" :src="userInfo.head_img" alt="头像" />
						{{ userInfo.user_nickname }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import twoCity from "@/utils/cityData/twoCity.min.js";
export default {
	name: "Header",
	data() {
		return {
			curArrs: ["110000", "110100"], // 当前的分类
			cityArrs: twoCity,
			isInit: true, // 是不是初始化
		};
	},
	computed: {
		...mapState({
			curWeather: (state) => state.curWeather,
			userInfo: (state) => state.userInfo,
			curProvince: (state) => state.curProvince,
			curCity: (state) => state.curCity,
		}),
	},
	watch: {
		curCity: {
			handler(newVal) {
				if (this.isInit) {
					let a = [];
					let obj =
						this.cityArrs.filter((item) => {
							return item.n == this.curProvince;
						})[0] || {};

					a.push(obj.c);
					if (obj.d) {
						let obj2 =
							obj.d.filter((item) => {
								return item.n == newVal;
							})[0] || {};

						a.push(obj2.c);
						this.curArrs = a;
					}

					this.isInit = false;
				}
			},
			deep: true,
		},
	},
	created() {},
	methods: {
		jumpAbout() {
			this.$router.push({
				name: "About",
			});
		},
		change(e) {
			let obj =
				this.cityArrs.filter((item) => {
					return item.c == e[0];
				})[0] || {};

			let curProvince = obj.n;
			if (obj.d) {
				let obj2 =
					obj.d.filter((item) => {
						return item.c == e[1];
					})[0] || {};

				let curCity = obj2.n;

				this.$store.commit("changeState", {
					type: "curProvince",
					data: curProvince || "北京市",
				});

				this.$store.commit("changeState", {
					type: "curCity",
					data: curCity || "北京市",
				});

				this.Timer = setTimeout(() => {
					clearTimeout(this.Timer);

					this.$store.dispatch("getWeatherForecast");
				}, 200);
			}
		},
		// 退出登录
		logout() {
			this.$store.commit("logout", {});
			this.$message("退出登录成功");

			this.$router.push({ name: "Login" });
			clearTimeout(this.Timer);
		},
		logouts() {
			this.$store.commit("logout", {});
			//	this.$message("退出登录成功");
			this.$router.push({ name: "Login" });
			clearTimeout(this.Timer);
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.city {
		.el-cascader,
		.el-input__icon {
			line-height: 30px;
		}

		.el-input {
			height: 30px;
		}

		input {
			width: 120px;
			border: none;
			height: 30px;
		}

		span {
			height: 30px;
		}
	}
}

.Header {
	@extend .bglr;

	.con {
		padding: 35px 0;

		.logo {
			width: 308px;
			height: 40px;
		}

		.right {
			.city-wrap {
				margin-right: 64px;
				padding: 12px 24px;
				background: #ffffff;
				border-radius: 56px;

				.weather {
					img {
						width: auto;
						height: 18px;
						margin-right: 4px;
					}
				}
			}

			.user {
				img {
					width: 48px;
					height: 48px;
					border-radius: 50%;
					overflow: hidden;
				}
			}
		}
	}
}
</style>
