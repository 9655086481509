"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "../views/Login.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		redirect: "/index",
		children: [
			{
				path: "/index",
				name: "Index",
				meta: {
					title: "首页",
				},
				component: () => import("../views/Index.vue"), // 首页
			},
			{
				path: "/login",
				name: "Login",
				// route level code-splitting
				// this generates a separate chunk (login.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				meta: {
					title: "登录",
				},
				component: () =>
					import(/* webpackChunkName: "login" */ "../views/Login.vue"), // 登录页面
			},
			{
				path: "/lists",
				name: "Lists",
				meta: {
					title: "政策列表",
				},
				component: () => import("../views/Lists.vue"), // 列表页
			},
			{
				path: "/citys",
				name: "Citys",
				meta: {
					title: "城市列表",
				},
				component: () => import("../views/Citys.vue"), // 列表页
			},
			{
				path: "/about",
				name: "About",
				component: () => import("../views/about/Index.vue"), // 个人中心
				redirect: "/personal",
				children: [
					{
						path: "/personal",
						name: "Personal",
						meta: {
							title: "个人中心",
						},
						component: () => import("../views/about/Personal.vue"), // 个人中心
					},
					{
						path: "/order",
						name: "Order",
						meta: {
							title: "我的订单",
						},
						component: () => import("../views/about/Order.vue"), // 我的订单
					},
					{
						path: "/vip",
						name: "Vip",
						meta: {
							title: "我的会员",
						},
						component: () => import("../views/about/Vip.vue"), // 我的会员
					},
					{
						path: "/release",
						name: "Release",
						meta: {
							title: "我的发布",
						},
						component: () => import("../views/about/Release.vue"), // 我的发布
					},
					{
						path: "/collection",
						name: "Collection",
						meta: {
							title: "我的收藏",
						},
						component: () => import("../views/about/Collection.vue"), // 我的收藏
					},
					{
						path: "/footprint",
						name: "Footprint",
						meta: {
							title: "足迹",
						},
						component: () => import("../views/about/Footprint.vue"), // 我的足迹
					},
					{
						path: "/integral",
						name: "Integral",
						meta: {
							title: "我的足迹",
						},
						component: () => import("../views/about/Integral.vue"), // 我的积分
					},
					{
						path: "/wallet",
						name: "Wallet",
						meta: {
							title: "我的钱包",
						},
						component: () => import("../views/about/Wallet.vue"), // 我的钱包
					},
					{
						path: "/record",
						name: "Record",
						meta: {
							title: "下载记录",
						},
						component: () => import("../views/about/Record.vue"), // 下载记录
					},
					{
						path: "/invoice",
						name: "Invoice",
						meta: {
							title: "电子发票",
						},
						component: () => import("../views/about/Invoice.vue"), // 电子发票
					},
					{
						path: "/share",
						name: "Share",
						meta: {
							title: "分享",
						},
						component: () => import("../views/about/Share.vue"), // 分享APP
					},
					{
						path: "/help",
						name: "Help",
						meta: {
							title: "帮助中心",
						},
						component: () => import("../views/about/Help.vue"), // 帮助中心
					},
					{
						path: "/contact",
						name: "Contact",
						meta: {
							title: "联系我们",
						},
						component: () => import("../views/about/Contact.vue"), // 联系我们
					},
					{
						path: "/message",
						name: "Message",
						meta: {
							title: "系统消息",
						},
						component: () => import("../views/about/Message.vue"), // 系统消息
					},
					{
						path: "/setting",
						name: "Setting",
						meta: {
							title: "系统设置",
						},
						component: () => import("../views/about/Setting.vue"), // 系统设置
					},
				],
			},
			{
				path: "/detail",
				name: "Detail",
				meta: {
					title: "详情",
				},
				component: () => import("../views/Detail.vue"), // 详情页面
			},
			{
				path: "/agreement",
				name: "Agreement",
				meta: {
					title: "",
				},
				component: () => import("../views/Agreement.vue"), // 其他页面
			},
			{
				path: "/feedback",
				name: "Feedback",
				meta: {
					title: "意见反馈",
				},
				component: () => import("../views/Feedback.vue"), // 意见反馈
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

export default router;
