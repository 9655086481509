<template>
	<div class="Home">
		<Header></Header>
		<keep-alive include="Index,Lists,About">
			<router-view style="min-height: 600px"></router-view>
		</keep-alive>
		<Footer></Footer>

		<!-- 在线客服 -->
		<CustomerService
			v-if="$store.state.userInfo && $store.state.userInfo.user_token"
		></CustomerService>
	</div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CustomerService from "@/components/CustomerService.vue";

export default {
	name: "Home",
	data() {
		return {
			token: "", // 记录 token
		};
	},
	components: {
		Header,
		Footer,
		CustomerService,
	},

	watch: {
		// 监听 token 的变化 然后 获取用户信息
		"$store.getters.token": {
			handler(newVal) {
				if (newVal && newVal != this.token) {
					this.token = newVal;
					if (newVal.length > 5) {
						this.change();
					}
				}
			},
			immediate: true,
			deep: true,
		},
	},
	created() {
		this.getCity();
		// if (this.$store.state.userInfo.user_token.length == 2) {
		// 	//	location.reload();
		// 	//console.log("555555555555555555");
		// 	this.$store.commit("login", { user_token: "55" });
		// }
	},
	methods: {
		change() {
			this.$API
				.getUserInfo({
					user_id: this.$store.state.userInfo.id,
				})
				.then((ress) => {
					if (ress.code == 1) {
						let obj = { ...this.$store.state.userInfo, ...ress.data };
						this.$store.commit("login", obj);
						// 重新获取天气
						this.$store.dispatch("getWeatherForecast");
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		getCity() {
			this.$API.threeCity({}).then((res) => {
				if (res.code == 1) {
					this.$localSetItem("threeCity", res.data);
				}
			});

			this.$axios
				.get("https://restapi.amap.com/v3/ip", {
					params: {
						key: "8bbfbc5b5b7aba4597ccf5c04637f138",
					},
				})
				.then((res) => {
					if (res.data.status == 1) {
						this.$store.commit("changeState", {
							type: "curProvince",
							data: res.data.province || "北京市",
						});

						this.$store.commit("changeState", {
							type: "curCity",
							data: res.data.city || "北京市",
						});

						// 重新获取天气
						this.$store.dispatch("getWeatherForecast");
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>
<style lang="scss">
.Home {
	min-height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
</style>
