<template>
	<div class="Empty">
		<div class="flex align-center justify-center p32 flex-direction">
			<img src="@/assets/public/empty.png" alt="暂无数据" />
			暂无数据
		</div>
	</div>
</template>
<script>
export default {
	name: "Empty",
};
</script>
<style scoped lang="scss">
.Empty {
}
</style>
