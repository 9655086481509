<template>
	<div class="CustomerService" :style="{ bottom: bottom + 'px' }">
		<!-- 小图标 start -->
		<div
			v-show="curType == 'icon'"
			@click="curType = 'big'"
			class="icon flex align-center justify-center cursor"
		>
			<img src="@/assets/imgs/customer-service-icon.png" alt="客服" />
		</div>
		<!-- 小图标 end -->

		<!-- 小样式 start -->
		<div v-show="curType == 'big'" class="big">
			<div class="close fs16 fw-7 font-ff flex align-center justify-between">
				在线客服<i @click="curType = 'icon'" class="el-icon-close"></i>
			</div>
			<iframe
				style="
					width: 500px;
					height: 610px;
					background-color: #ffffff;
					border-radius: 8px;
				"
				frameborder="0"
				:src="$config.BASE_URL + '/chat.html'"
			></iframe>
		</div>
		<!-- 小样式 end -->
	</div>
</template>
<script>
export default {
	name: "CustomerService",
	data() {
		return {
			curType: "icon", // 当前的类型  icon 小图标; big 大的;
			height: 920,
			bottom: 185,
		};
	},
	watch: {
		curType: {
			handler(newVal) {
				if (this.height < 800) {
					if (newVal == "big") {
						this.bottom = 40;
					} else {
						this.bottom = 185;
					}
				}
			},
		},
	},
	created() {
		let height = document.documentElement.scrollHeight;
		this.height = height;

		this.$bus.$on("CustomerServiceChange", (e) => {
			this.curType = e.curType;
		});
	},
	destroyed() {
		this.$bus.$off("CustomerServiceChange");
	},
};
</script>

<style scoped lang="scss">
.CustomerService {
	position: fixed;
	z-index: 2000;
	right: 50%;
	bottom: 185px;
	transform: translateX(600px);

	.icon {
		width: 64px;
		height: 64px;
		background: #ff8300;
		box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.16);
		border-radius: 8px;

		img {
			width: 32px;
			height: 32px;
		}
	}

	.big {
		.close {
			border-radius: 8px 8px 0 0;
			width: 100%;
			background-color: #007aff;
			padding: 12px;
			padding-bottom: 22px;
			position: relative;
			top: 10px;
			left: 0;

			.el-icon-close {
				cursor: pointer;
				font-weight: 400;
				font-size: 28px;
			}
		}
	}
}
</style>
