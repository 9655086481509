"use strict";
import Vue from "vue";
import Vuex from "vuex";
import { localSetItem, localRemoveItem } from "@/config/local";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// ! process.env.NODE_ENV == "development" 不行 因为打包测试的时候 这个值为生产模式
		ISDEV: 0, // 是否开发模式 1 是; 0 否;
		userInfo: {}, // 用户信息
		curProvince: "北京市", // 当前的省
		curCity: "北京市", // 当前的城市
		curWeather: {}, // 当前的天气数据
	},
	getters: {
		// 获取 token
		token(state) {
			if (state.userInfo && Object.keys(state.userInfo).length > 0) {
				return state.userInfo.user_token;
			} else {
				return "";
			}
		},
	},
	mutations: {
		// 登录成功
		login(state, payload) {
			state.userInfo = payload;
			localSetItem("userInfo", payload);
			localSetItem("token", payload.user_token);
		},
		// 退出登录
		logout(state, payload) {
			console.log("store logout payload", payload);
			state.userInfo = {};
			localRemoveItem("userInfo");
			localRemoveItem("token");
		},
		// 改变 state 的值
		changeState(state, payload) {
			state[payload.type] = payload.data;
		},
	},
	actions: {
		// 获取天气
		getWeatherForecast({ state, commit, getters }) {
			let defaultArr = [
				"阿坝藏族羌族自治州",
				"白沙黎族自治县",
				"保亭黎族苗族自治县",
				"巴音郭楞蒙古自治州",
				"博尔塔拉蒙古自治州",
				"昌江黎族自治县",
				"昌吉回族自治州",
				"楚雄彝族自治州",
				"海北藏族自治州",
				"海南藏族自治州",
				"海西蒙古族藏族自治州",
				"黄南藏族自治州",
				"红河哈尼族彝族自治州",
				"临夏回族自治州",
				"乐东黎族自治县",
				"陵水黎族自治县",
				"凉山彝族自治州",
				"文山壮族苗族自治州",
				"湘西土家族苗族自治州",
				"锡林郭勒盟",
				"西双版纳傣族自治州",
				"大理白族自治州",
				"德宏傣族景颇族自治州",
				"迪庆藏族自治州",
				"怒江傈僳族自治州",
				"黔东南苗族侗族自治州",
				"黔南布依族苗族自治州",
				"黔西南布依族苗族自治州",
				"琼中黎族苗族自治县",
				"甘南藏族自治州",
				"果洛藏族自治州",
				"甘孜藏族自治州",
				"延边朝鲜族自治州",
				"玉树藏族自治州",
				"伊犁哈萨克自治州",
				"克孜勒苏柯尔克孜自治州",
				"恩施土家族苗族自治州",
			];

			let arr = [
				{
					name: "巴音郭楞蒙古自治州",
					key: "巴音郭楞",
				},
				{
					name: "博尔塔拉蒙古自治州",
					key: "博尔塔拉",
				},
				{
					name: "海西蒙古族藏族自治州",
					key: "海西",
				},
				{
					name: "红河哈尼族彝族自治州",
					key: "红河",
				},
				{
					name: "湘西土家族苗族自治州",
					key: "湘西",
				},
				{
					name: "怒江傈僳族自治州",
					key: "怒江",
				},
				{
					name: "黔东南苗族侗族自治州",
					key: "黔东南",
				},
				{
					name: "黔南布依族苗族自治州",
					key: "黔南",
				},
				{
					name: "黔西南布依族苗族自治州",
					key: "黔西南",
				},
				{
					name: "延边朝鲜族自治州",
					key: "延边",
				},
				{
					name: "伊犁哈萨克自治州",
					key: "伊犁",
				},
				{
					name: "恩施土家族苗族自治州",
					key: "恩施",
				},
			];

			let curCity = state.curCity || "北京市";
			let city = replaceStr(curCity);
			if (getters.token) {
				axios
					.get("https://www.yiketianqi.com/free/day", {
						params: {
							appid: "55426837",
							appsecret: "6IIWOYlH",
							unescape: 1,
							city,
						},
					})
					.then((res) => {
						if (!res.errcode) {
							commit("changeState", {
								type: "curWeather",
								data: res.data,
							});
						} else {
							commit("changeState", {
								type: "curWeather",
								data: {},
							});
						}
					});
			}

			function replaceStr(str) {
				if (defaultArr.indexOf(str) == -1) {
					// 剔除市
					if (str.lastIndexOf("市") == str.length - 1) {
						return str.slice(0, str.length - 1);
					}

					// 剔除地区
					if (str.lastIndexOf("地区") == str.length - 1) {
						return str.slice(0, str.length - 1);
					}

					// 剔除区
					if (str.lastIndexOf("区") == str.length - 1) {
						return str.slice(0, str.length - 1);
					}

					// 剔除县
					if (str.lastIndexOf("县") == str.length - 1) {
						return str.slice(0, str.length - 1);
					}
				}

				// 是否在 arr 中有
				let isArr = arr
					.map((item) => {
						return item.name;
					})
					.indexOf(str);

				if (isArr == -1) {
					let num = str.indexOf("族");
					return str.slice(0, num - 1);
				} else {
					return arr[isArr].key;
				}
			}
		},
	},
	modules: {},
});
