"use strict";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// 无关系组件之间的通信
Vue.prototype.$bus = new Vue();

// 引入全局组件
import "./config/globalComponents";
import "./element-ui";
import { Message } from "element-ui";

import API from "./api";
Vue.prototype.$API = API;

import axios from "axios";
Vue.prototype.$axios = axios;

import { localSetItem, localGetItem, localRemoveItem } from "@/config/local";
Vue.prototype.$localSetItem = localSetItem;
Vue.prototype.$localGetItem = localGetItem;
Vue.prototype.$localRemoveItem = localRemoveItem;

import { pageScrollTop, BASE_URL, OSS_IMG_BASE_URL } from "./config/index";
Vue.prototype.$pageScrollTop = pageScrollTop;

import { validate } from "./config/validate";
Vue.prototype.$validate = validate;

document.title = "好好帮";
// 路由权限管理
router.beforeEach((to, from, next) => {
	//	console.log(to.name);
	let userInfo = localGetItem("userInfo") || {};
	let token = "";
	if (userInfo && userInfo instanceof Object && userInfo.user_token) {
		token = userInfo.user_token;
		if (store.state.userInfo && Object.keys(store.state.userInfo).length == 0) {
			store.commit("login", userInfo);
		}
	}
	if (to.name == "Index" && !token) {
		store.commit("login", { user_token: "555" });
	}

	setTimeout(() => {
		if (to.name == "Index" && !token) {
			console.log("SHIXYASAD", to.name);
			return false;
		}
	}, 1000);

	// if (to.name == "Lists" && !token) {
	// 	Message("请先登录");
	// 	console.log("444444444444");
	// 	next("/login");
	// 	return false;
	// }

	if (token || store.getters.token) {
		if (!store.getters.token) {
			Message("登录失效，请重新登录");
			next("/login");
			return false;
		}

		if (to.name == "Login") {
			next("/index");
		} else {
			next();
		}
	} else {
		if (to.name != "Login") {
			next("/");
		} else {
			next();
		}
	}
});

/**
 * ! 是否可以跳转详情
 * @param {Object} params 请求参数
 * @param {Boolean} sign 如果有值的话 是否需要返回 默认 false
 * @returns  BOOLEAN OBJECT
 */
Vue.prototype.$isEnterDetailFun = async function (params, sign) {
	sign = sign || false;
	try {
		let res = await API.isEnterDetail(params);
		// ├─ is_pay	Integer[整数]	是否支付 0 否 1是   (1 不能; 0 能;)
		// ├─ score	Integer[整数]	积分
		// ├─ user_money	Float[浮点数]	余额
		// ├─ score_pay	Integer[整数]	积分支付  (需要支付的积分)
		// ├─ user_money_pay	Float[浮点数]	余额支付金额  (需要支付的金额)
		if (res.code == 1) {
			if (sign) {
				return res.data;
			}

			return res.data.is_pay;
		}
	} catch (error) {
		return "catch";
	}
};

Vue.prototype.$config = { BASE_URL, OSS_IMG_BASE_URL };

/**
 * ! 替换  最后一级为空的时候 cascader 展示暂无数据函数
 * @param {Array,Object} gData 源对象
 * @param {Object} gOpt 配置项
 * 			{
 * 				d: 'children', 需要替换的子集 key
 * 			}
 */
Vue.prototype.$replaceChildrenEmpty = function (gData, gOpt) {
	function fun(data, opt) {
		let { d = "children" } = opt;
		if (!(data instanceof Object) && !(data instanceof Array)) {
			console.log("需要处理的数据 不是对象也不是数组");
			return data;
		}

		if (!(data instanceof Array) && data instanceof Object) {
			data = data[d];
		}

		data.forEach((item) => {
			if (!item[d] || item[d].length < 1) {
				item[d] = null;
			} else {
				fun(item[d], opt);
			}
		});

		return data;
	}

	return fun(gData, gOpt);
};

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
