"use strict";
import myHttp from "./myAxios";
import store from "../store";

/**
 *
 * @param {options} options 参数集合
 * @param {url}    url *: 地址
 */

const API = {
	uploadFile: "/v1/5d5fa8984f0c2",

	// 发送验证码
	SendVerifyCode: (params) => {
		// type 1 注册(未注册手机号); 2 忘记密码(已经注册的手机号); 3 微信绑定(绑定手机号) 短信登录; 4 修改手机号; 5 绑定手机号;
		// mobile 手机号
		params.is_test = store.state.ISDEV;
		return myHttp({
			url: "/v1/5b5bdc44796e8",
			method: "POST",
			params,
		});
	},

	// 验证码登录
	Login: (params) => {
		// mobile: phone,
		// code: vercode,
		// user_source: SYSTEM_CONFIG.platform,

		params.is_test = store.state.ISDEV;

		return myHttp({
			url: "/v1/5c78dca45ebc1",
			method: "POST",
			params,
		});
	},

	// 密码登录
	LoginPaw: (params) => {
		// mobile: phone,
		// password,
		// clientId,
		// appSystem,
		params.is_test = store.state.ISDEV;

		return myHttp({
			url: "/v1/5c78dbfd977cf",
			method: "POST",
			params,
		});
	},

	// 注册
	register: (params) => {
		// mobile: phone,
		// code: vercode,
		// type: 1,
		// password: password,
		// invite_code: invite_code,
		// user_source: SYSTEM_CONFIG.platform,
		params.is_test = store.state.ISDEV;

		return myHttp({
			url: "/v1/5cad9f63e4f94",
			method: "POST",
			params,
		});
	},

	// 忘记密码
	forget: (params) => {
		// password: password,
		// mobile: phone,
		// code: vercode,
		params.is_test = store.state.ISDEV;

		return myHttp({
			url: "/v1/5caeeba9866aa",
			method: "POST",
			params,
		});
	},

	// 微信绑定
	bindWx: (params) => {
		return myHttp({
			url: "/v1/623e8b212d12d",
			method: "POST",
			params,
		});
	},

	// 是否首次登录
	isFirstLogin: (params) => {
		return myHttp({
			url: "/v1/6206019133cf2",
			method: "POST",
			params,
		});
	},

	// 首页数据合集
	homeData: (params) => {
		return myHttp({
			url: "/v1/5fc0cbfc71f70",
			method: "POST",
			params,
		});
	},

	homeList: (params) => {
		return myHttp({
			url: "/v1/620f0e38dc2ed",
			method: "POST",
			params,
		});
	},

	threeCity: (params) => {
		return myHttp({
			url: "/v1/620e04b9c75f9",
			method: "POST",
			params,
		});
	},

	// 从首页搜搜接口
	searchAjax: (params) => {
		return myHttp({
			url: "/v1/620620dceee54",
			method: "POST",
			params,
		});
	},

	// 金刚区进来 搜索接口
	kingSearchAjax: (params) => {
		return myHttp({
			url: "/v1/620f3608c7642",
			method: "POST",
			params,
		});
	},

	// 分类接口
	typeAjax: (params) => {
		return myHttp({
			url: "/v1/620efbd2513da",
			method: "POST",
			params,
		});
	},

	// 是否可以跳转详情
	isEnterDetail: (params) => {
		return myHttp({
			url: "/v1/62062434a658f",
			method: "POST",
			params,
		});
	},

	// 余额积分支付
	jumpDetailPay: (params) => {
		return myHttp({
			url: "/v1/6209d0f069872",
			method: "POST",
			params,
		});
	},

	detailAjax: (params) => {
		return myHttp({
			url: "/v1/62077acbbec01",
			method: "POST",
			params,
		});
	},

	// 下载文件
	downFile: (params) => {
		return myHttp({
			url: "/v1/620f444c19c4d",
			method: "POST",
			params,
		});
	},

	// 添加收藏 取消收藏
	detailCollect: (params) => {
		return myHttp({
			url: "/v1/5d89f462c9c21",
			method: "POST",
			params,
		});
	},

	// 删除政策
	delDetail: (params) => {
		return myHttp({
			url: "/v1/621f3452055e4",
			method: "POST",
			params,
		});
	},

	// 订单列表
	orderAjax: (params) => {
		return myHttp({
			url: "/v1/620b5055732b1",
			method: "POST",
			params,
		});
	},

	myFeedback: (params) => {
		return myHttp({
			url: "/v1/5cb97ad30ea88",
			method: "POST",
			params,
		});
	},

	// 会员充值类别
	memberList: (params) => {
		return myHttp({
			url: "/v1/5ff6bd4eaf508",
			method: "POST",
			params,
		});
	},

	// 会员权益
	memberBenefit: (params) => {
		return myHttp({
			url: "/v1/62104b8425c04",
			method: "POST",
			params,
		});
	},

	// 购买记录
	vipOrderList: (params) => {
		return myHttp({
			url: "/v1/620b4eaad06a6",
			method: "POST",
			params,
		});
	},

	// 我的发布列表
	issueList: (params) => {
		return myHttp({
			url: "/v1/620a1a6e943ef",
			method: "POST",
			params,
		});
	},

	// 发布的分类
	issueType: (params) => {
		return myHttp({
			url: "/v1/620e15a486c17",
			method: "POST",
			params,
		});
	},

	// 发布
	issue: (params) => {
		return myHttp({
			url: "/v1/620a10b8c9c94",
			method: "POST",
			params,
		});
	},

	// 编辑
	issueEdit: (params) => {
		return myHttp({
			url: "/v1/62149fb7648a3",
			method: "POST",
			params,
		});
	},

	// 删除政策
	delDetail1: (params) => {
		return myHttp({
			url: "/v1/621f3452055e4",
			method: "POST",
			params,
		});
	},

	// 我的收藏
	collectList: (params) => {
		return myHttp({
			url: "/v1/620a1cd209405",
			method: "POST",
			params,
		});
	},

	//  取消收藏  列表 批量
	cancelCollectList: (params) => {
		return myHttp({
			url: "/v1/5d8a1c18cf048",
			method: "POST",
			params,
		});
	},

	// 足迹列表
	footprintList: (params) => {
		return myHttp({
			url: "/v1/6231362e78160",
			method: "POST",
			params,
		});
	},

	// 积分列表
	integralList: (params) => {
		return myHttp({
			url: "/v1/5d75bbc77d252",
			method: "POST",
			params,
		});
	},

	// 金额/积分 余额
	remainingBalancePoints: (params) => {
		return myHttp({
			url: "/v1/5cc45274d6be9",
			method: "POST",
			params,
		});
	},

	// 获取会员签到汇总信息
	curSign: (params) => {
		return myHttp({
			url: "/v1/5d78c19d31461",
			method: "POST",
			params,
		});
	},

	// 签到
	signAjax: (params) => {
		return myHttp({
			url: "/v1/5caf00505dd00",
			method: "POST",
			params,
		});
	},

	// 下载列表
	recordAjax: (params) => {
		return myHttp({
			url: "/v1/620b5219b0b99",
			method: "POST",
			params,
		});
	},
	//我的分享接口
	shareAjax: (params) => {
		return myHttp({
			url: "/v1/5fe465c1a1967",
			method: "POST",
			params,
		});
	},
	//footer二维码接口
	shareAppAjax: (params) => {
		return myHttp({
			url: "/v1/6260b3cc93605",
			method: "POST",
			params,
		});
	},
	// 帮助列表
	helpAjax: (params) => {
		return myHttp({
			url: "/v1/5d648c8d37977",
			method: "POST",
			params,
		});
	},

	connectAjax: (params) => {
		return myHttp({
			url: "/v1/620b1ced5eeb9",
			method: "POST",
			params,
		});
	},

	// 获取系统消息
	getOperationGetSystemMsgList: (params) => {
		return myHttp({
			url: "/v1/5cc56966e9287",
			method: "GET",
			params,
		});
	},
	// 积分支付接口
	getOperationjifenplay: (params) => {
		return myHttp({
			url: "/v1/6368ff037547f",
			method: "POST",
			params,
		});
	},
	// 积分兑换余额
	shengyujifentixian: (params) => {
		return myHttp({
			url: "/v1/6367627e21887",
			method: "POST",
			params,
		});
	},
	postOperationGetArticleDetail: (params) => {
		return myHttp({
			url: "/v1/62353c346b0aa",
			method: "POST",
			params,
		});
	},

	// 校验验证码
	postUserCheckVerifyCode: (params) => {
		return myHttp({
			url: "/v1/5f6db4db8abcf",
			method: "POST",
			params,
		});
	},

	// 修改手机号
	PostUserUpdateMobile: (params) => {
		return myHttp({
			url: "/v1/5f6c915d69d1f",
			method: "POST",
			params,
		});
	},

	// 底部的信息
	footerInfo: (params) => {
		return myHttp({
			url: "v1/5f3de8d284639",
			method: "POST",
			params,
		});
	},

	// 获取金额信息
	getMyMoney: (params) => {
		return myHttp({
			url: "/v1/5cc45274d6be9",
			method: "POST",
			params,
		});
	},

	// 金额流水
	getMoneyDetail: (params) => {
		return myHttp({
			url: "/v1/5cc45422e5c87",
			method: "POST",
			params,
		});
	},

	// 获取绑定的提现账号
	getWithdrawAccount: (params) => {
		return myHttp({
			url: "/v1/5d7b9bd1c7d7c",
			method: "GET",
			params,
		});
	},

	// 绑定提现账号
	postBindWithdrawAccount: (params) => {
		return myHttp({
			url: "/v1/5d7b7d4007529",
			method: "POST",
			params,
		});
	},

	// 最小提现金额
	postLimitWithdraw: (params) => {
		return myHttp({
			url: "/v1/5f802beb5cb06",
			method: "POST",
			params,
		});
	},

	// 提现申请
	withdraw: (params) => {
		return myHttp({
			url: "/v1/5ce25d5e1ffb8",
			method: "POST",
			params,
		});
	},

	// 获取充值规则
	getRechargeRule: (params) => {
		return myHttp({
			url: "/v1/5cd2b4631e656",
			method: "POST",
			params,
		});
	},

	// 生成订单
	postAddOrder: (params) => {
		return myHttp({
			url: "/v1/5d784b976769e",
			method: "POST",
			params,
		});
	},

	// 开票历史列表
	applyHistoryList: (params) => {
		return myHttp({
			url: "/v1/5fd03d2db823b",
			method: "POST",
			params,
		});
	},

	// 开发票详情列表
	applyHistoryDetailList: (params) => {
		return myHttp({
			url: "/v1/5fdc6f1639771",
			method: "POST",
			params,
		});
	},

	// 发票列表
	applyList: (params) => {
		return myHttp({
			url: "/v1/620b6a5ff398e",
			method: "POST",
			params,
		});
	},

	// 开发票
	applyInvoice: (params) => {
		return myHttp({
			url: "/v1/5fcf53f28990e",
			method: "POST",
			params,
		});
	},

	// 获取微信登录 url
	wxGetUrl: (params) => {
		return myHttp({
			url: "/v1/623d37f53f86f",
			method: "POST",
			params,
		});
	},

	// 微信登录的回调
	wxLoginCallback: (params) => {
		return myHttp({
			url: "/v1/623e7af8d5100",
			method: "POST",
			params,
		});
	},

	// 生成微信二维码
	generateWxCode: (params) => {
		return myHttp({
			url: "/v1/5d7868c138418",
			method: "POST",
			params,
		});
	},
	// 生成支付宝二维码
	AlipayCode: (params) => {
		return myHttp({
			url: "/v1/5d7a088403825",
			method: "POST",
			params,
		});
	},

	// 支付回调
	payCallback: (params) => {
		return myHttp({
			url: "/v1/623e902b1729f",
			method: "POST",
			params,
		});
	},

	// 开通 vip 余额支付
	balancePay: (params) => {
		return myHttp({
			url: "/v1/5e489f45a051d",
			method: "POST",
			params,
		});
	},

	1: (params) => {
		return myHttp({
			url: "/v1/1",
			method: "POST",
			params,
		});
	},

	// 之前的 --------------------------------------------------------

	// 消息 获取列表
	getNews: (params) => {
		// msg_type 消息类型  不传为全部; 1 系统消息; 3 申请消息;
		// pagesize 分页条数
		// page 当前的页码
		return myHttp({
			url: "/v1/61b301fb5f93c",
			method: "POST",
			params,
		});
	},

	// 消息 全部设置为已读
	setAllRead: (params) => {
		return myHttp({
			url: "/v1/61b309214757a",
			method: "POST",
			params,
		});
	},

	// 消息 获取未读数
	getReadNum: (params) => {
		return myHttp({
			url: "/v1/61b308a1ed188",
			method: "POST",
			params,
		});
	},

	// 消息 获取详情
	getNewDetail: (params) => {
		return myHttp({
			url: "/v1/61b304d8d35db",
			method: "POST",
			params,
		});
	},

	// 个人中心 获取详情
	getUserInfo: (params) => {
		return myHttp({
			url: "/v1/5c78c4772da97",
			method: "POST",
			params,
		});
	},

	// 个人中心 修改用户信息
	changeUserInfo: (params) => {
		return myHttp({
			url: "/v1/5cb54af125f1c",
			method: "POST",
			params,
		});
	},

	// 获取协议
	getAgreement: (params) => {
		// id 24 操作指南; 23 隐私政策; 15 注册协议; 4 公告弹窗;
		return myHttp({
			url: "/v1/5d63befcb25d9",
			method: "POST",
			params,
		});
	},

	// 获取联系我们
	getContactUs: (params) => {
		return myHttp({
			url: "/v1/61d6ad553fa58",
			method: "POST",
			params,
		});
	},

	// 意见反馈  反馈类型
	getFeedbackType: (params) => {
		return myHttp({
			url: "/v1/5d63ba953ee01",
			method: "POST",
			params,
		});
	},

	// 意见反馈  提交
	addFeedback: (params) => {
		return myHttp({
			url: "/v1/5cc3f28296cf0",
			method: "POST",
			params,
		});
	},
};

export default API;
