<template>
	<div class="Footer flex justify-between">
		<div class="w">
			<div v-if="userInfo.user_token" class="con-wrap flex">
				<div class="con text-l font-99">
					<!-- 政策 -->
					<div class="policy">
						<router-link :to="{ name: 'Agreement', query: { id: 124 } }"
							>关于我们</router-link
						>
						<router-link :to="'/contact'">联系我们</router-link>
						<router-link :to="{ name: 'Feedback' }">意见反馈</router-link>
						<router-link :to="{ name: 'Agreement', query: { id: 16 } }"
							>隐私与政策</router-link
						>
						<router-link :to="{ name: 'Agreement', query: { id: 15 } }"
							>用户注册协议</router-link
						>
					</div>

					<!-- 备案 -->
					<div class="filing pt32">
						<!-- <a target="_blank" href="https://www.baidu.com/"
							>陕公网安备 11000002000088号</a
						> -->
						<a target="_blank" href="https://beian.miit.gov.cn"
							>黔ICP备2022001485号
						</a>
						<a class="ml8" target="_blank"
							>增值电信业务经营许可证：黔B2-20230018
						</a>
					</div>

					<!-- 备案信息 -->
					<div class="filing-info pt32">
						© CopyRight 2021-2024 好好帮www.haohaobangcn.com All Rights
						Reserved. 黔ICP备2022001485号
					</div>
				</div>
				<div class="right flex text-c pr00">
					<div class="android">
						<img :src="android_qrcode" />
						<div>Android</div>
					</div>
					<div class="ios">
						<img :src="ios_qrcode" />
						<div>Ios</div>
					</div>
				</div>
			</div>
			<div v-else class="con text-c font-99">
				<!-- 政策 -->
				<div class="policy">
					<a
						target="_blank"
						href="https://app.haohaobangcn.com/agreement/privacy.html"
						>隐私与政策</a
					>
					<a
						target="_blank"
						href="https://app.haohaobangcn.com/agreement/user.html"
						>用户注册协议</a
					>
					<!-- <router-link :to="{ name: 'Agreement', query: { id: 16 } }"
						>隐私与政策</router-link
					>
					<router-link :to="{ name: 'Agreement', query: { id: 15 } }"
						>用户注册协议</router-link
					> -->
				</div>

				<!-- 备案 -->
				<div class="filing pt32">
					<!-- <a target="_blank" href="https://www.baidu.com/"
						>陕公网安备 11000002000088号</a
					> -->
					<a target="_blank" href="https://beian.miit.gov.cn"
						>黔ICP备2022001485号</a
					>
				</div>

				<!-- 备案信息 -->
				<div class="filing-info pt32">
					© CopyRight 2021-2024 好好帮www.haohaobangcn.com All Rights Reserved.
					黔ICP备2022001485号
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "Footer",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	watch: {
		userInfo: {
			handler(newVal) {
				if (newVal && newVal.user_token) {
					this.$API.shareAppAjax({}).then((res) => {
						if (res.code == 1) {
							let { android_qrcode, ios_qrcode } = res.data;
							this.android_qrcode = android_qrcode;
							this.ios_qrcode = ios_qrcode;
						}
					});
				}
			},
		},
	},
	created() {
		this.$API.shareAppAjax({}).then((res) => {
			if (res.code == 1) {
				let { android_qrcode, ios_qrcode } = res.data;
				this.android_qrcode = android_qrcode;
				this.ios_qrcode = ios_qrcode;
			}
		});
	},
	data() {
		return {
			android_qrcode: "",
			ios_qrcode: "",
		};
	},
};
</script>
<style scoped lang="scss">
.Footer {
	background: #282828;

	.con {
		padding: 62px 0;

		.policy {
			a {
				font-size: 16px;
				color: #ffffff;

				&:nth-child(2) {
					// margin: 0 104px;
					margin-left: 104px;
				}
			}
		}

		.filing {
			a {
				&:last-child {
					// margin-left: 104px;
				}
			}
		}

		a {
			color: #999999;
		}
	}

	.con-wrap {
		padding: 0 40px;

		.con {
			flex: 1;

			.policy {
				a {
					margin-left: 0;
					margin-right: 90px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.right {
			padding-left: 18px;
			padding-top: 62px;

			> div {
				margin-left: 50px;

				img {
					width: 120px;
					height: 120px;
				}

				div {
					margin-top: 20px;
					font-size: 16px;
					color: #ffffff;
				}
			}

			&:after {
				content: "";
				display: block;
				height: 140px;
				width: 1px;
				background-color: #3c3c3c;
				position: absolute;
				left: 0;
				top: 62px;
			}
		}
	}
}
</style>
