"use strict";
const BASE_URL = "https://app.haohaobangcn.com";
const OSS_IMG_BASE_URL = "https://haohaobang.oss-cn-beijing.aliyuncs.com/"; // 图片在 oss 上的位置

/**
 * ! 页面回到顶部
 */
let srcollTopTimer = null;
let pageScrollTop = function () {
	// window.document.body.scrollTop = 0;
	// window.document.documentElement.scrollTop = 0;
	clearInterval(srcollTopTimer);
	srcollTopTimer = setInterval(function () {
		var top = document.body.scrollTop || document.documentElement.scrollTop;
		var speed = top / 4;
		if (document.body.scrollTop != 0) {
			document.body.scrollTop -= speed;
		} else {
			document.documentElement.scrollTop -= speed;
		}
		if (top <= 0) {
			clearInterval(srcollTopTimer);
		}
	}, 16.7);
};

export { BASE_URL, OSS_IMG_BASE_URL, pageScrollTop };
